import { useHttpClient } from '../../http/index';
import { GetTradingAccountsResponse } from './types';

const { get } = useHttpClient();

export const useAccountApi = () => {
  const getTradingAccounts = async () => {
    const { data } = await get<GetTradingAccountsResponse>('/v1/accounts/trading');
    return { data };
  };

  return {
    getTradingAccounts,
  };
};
