import { AxiosError } from 'axios';
import { errorTracker, logger, httpErrorParser } from '@fsd/shared/lib';
import { useLocale } from '@fsd/shared/locale';
import { isAxiosError, showErrorMsg, getErrorMsg } from './lib';

export const errorHandler = async (
  error: unknown,
  customParser?: (error: AxiosError) => string | undefined,
  options: {
    skipErrorByOneOfCodes?: number[];
    multipleNotify?: boolean;
    showAllDefaultErrorMessageExtended?: boolean;
    errorMessageMapper?: (message: string) => string;
    description?: string;
    isCreateExtendedDefaultErrorMessage?: boolean;
    defaultErrorMessage?: string;
    msgByStatusCode?: Record<number, string>;
    parsersByStatusCode?: Record<number, (error: AxiosError) => string | undefined>;
    $t?: (msg: string) => string;
  } = {
    $t: useLocale().$t,
  },
): Promise<never> => {
  const {
    skipErrorByOneOfCodes = [422],
    showAllDefaultErrorMessageExtended = false,
    multipleNotify = false,

    errorMessageMapper,
    description,
    isCreateExtendedDefaultErrorMessage,
    defaultErrorMessage,
    msgByStatusCode,
    parsersByStatusCode,
    $t,
  } = options;

  try {
    if (isAxiosError(error)) {
      const { msg, statusCode } = httpErrorParser.parse(error, customParser, {
        description,
        defaultErrorMessage,
        errorMessageMapper,
        isCreateExtendedDefaultErrorMessage,
        $t,
        msgByStatusCode,
        parsersByStatusCode,
      });

      if (!statusCode || (statusCode && !skipErrorByOneOfCodes?.includes(statusCode))) {
        showErrorMsg(msg, multipleNotify);
      }
    }
  } catch (parsingError: unknown) {
    const errorMessage = getErrorMsg(
      {
        error: parsingError,
        description,
      },
      showAllDefaultErrorMessageExtended,
    );

    showErrorMsg(errorMessage);

    errorTracker.track(parsingError);
    logger.logError(parsingError, description);
  } finally {
    errorTracker.track(error);
    logger.logError(error, description);
  }

  return Promise.reject(error);
};
