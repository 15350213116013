import { AxiosResponse, AxiosError } from 'axios';
import { errorHandler, objectLib } from '@fsd/shared/lib';

const camelizeSuccessData = (resp: AxiosResponse): AxiosResponse => ({
  ...resp,
  data: objectLib.camelizeKeys(resp.data, {
    deep: true,
  }),
});

const parseFinalSuccessData = (resp: AxiosResponse): AxiosResponse => ({
  ...resp,
  data: resp?.data ? resp.data : resp,
});

const camelizeErrorData = (err: AxiosError): AxiosError => {
  const hasStatus = err?.response?.status;

  if (hasStatus && err.response) {
    const e = { ...err };
    if (e.response) {
      e.response.data = objectLib.camelizeKeys(e.response.data);
    }
    return e;
  }

  return err;
};

const rejectFinalErrorData = (err: AxiosError): Promise<AxiosError> => Promise.reject(err);

export const baseRespInterceptors = {
  success: [camelizeSuccessData, parseFinalSuccessData],
  error: [camelizeErrorData, rejectFinalErrorData, errorHandler],
};
