// module name
export const STORE_MODULE_GLOBAL = 'STORE_MODULE_GLOBAL';

// actions
export const ACTION_FETCH_USER_DATA = 'ACTION_FETCH_USER_DATA';
export const ACTION_FETCH_PARTNER_DATA = 'ACTION_FETCH_PARTNER_DATA';
export const ACTION_FETCH_ACCOUNTS_NUMBER = 'ACTION_FETCH_ACCOUNTS_NUMBER';
export const ACTION_FETCH_USER_PROFILE = 'ACTION_FETCH_USER_PROFILE';

export const ACTION_FETCH_TRADES = 'ACTION_FETCH_TRADES';
export const ACTION_FETCH_TRADES_TOTAL = 'ACTION_FETCH_TRADES_TOTAL';
export const ACTION_FETCH_TRADING_ACCOUNTS = 'ACTION_FETCH_TRADING_ACCOUNTS';
export const ACTION_FETCH_ALL_ACCOUNTS = 'ACTION_FETCH_ALL_ACCOUNTS';
export const ACTION_FETCH_ACCOUNT_PROGRESS = 'ACTION_FETCH_ACCOUNT_PROGRESS';
export const ACTION_CHANGE_TRADING_ACCOUNT_PASSWORD = 'ACTION_CHANGE_TRADING_ACCOUNT_PASSWORD';
export const ACTION_FETCH_ECN_PROMO = 'ACTION_FETCH_ECN_PROMO';
export const ACTION_ENABLE_ECN_PROMO = 'ACTION_ENABLE_ECN_PROMO';
export const ACTION_CHANGE_ACCOUNT_SETTINGS = 'ACTION_CHANGE_ACCOUNT_SETTINGS';

export const ACTION_ARCHIVE_TRADING_ACCOUNT = 'ACTION_ARCHIVE_TRADING_ACCOUNT';
export const ACTION_UN_ARCHIVE_TRADING_ACCOUNT = 'ACTION_UN_ARCHIVE_TRADING_ACCOUNT';
export const ACTION_FETCH_ARCHIVED_TRADING_ACCOUNTS = 'ACTION_FETCH_ARCHIVED_TRADING_ACCOUNTS';
export const ACTION_ADD_MAIN_CHANNEL_SOCKET_MESSAGE = 'ACTION_ADD_MAIN_CHANNEL_SOCKET_MESSAGE';

// mutations
export const MUTATION_SET_CURRENT_USER_ROLE = 'MUTATION_SET_CURRENT_USER_ROLE';
export const MUTATION_SET_USER_ROLES = 'MUTATION_SET_USER_ROLES';
export const MUTATION_SET_USER_DATA = 'MUTATION_SET_USER_DATA';
export const MUTATION_SET_USER_DATA_LOADING = 'MUTATION_SET_USER_DATA_LOADING';
export const MUTATION_SET_ACCOUNTS_DATA_LOADING = 'MUTATION_SET_ACCOUNTS_DATA_LOADING';
export const MUTATION_SET_ACCOUNTS_DATA = 'MUTATION_SET_ACCOUNTS_DATA';
export const MUTATION_SET_USER_PROFILE = 'MUTATION_SET_USER_PROFILE';
export const MUTATION_SET_IS_USER_PROFILE_LOADING = 'MUTATION_SET_IS_USER_PROFILE_LOADING';

export const MUTATION_SET_PARTNER_DATA = 'MUTATION_SET_PARTNER_DATA';
export const MUTATION_SET_PARTNER_DATA_LOADING = 'MUTATION_SET_PARTNER_DATA_LOADING';

export const MUTATION_SET_ALL_ACCOUNTS = 'MUTATION_SET_ALL_ACCOUNTS';
export const MUTATION_SET_IS_ALL_ACCOUNTS_LOADING = 'MUTATION_SET_IS_ALL_ACCOUNTS_LOADING';
export const MUTATION_SET_TRADING_ACCOUNTS_LOADING = 'MUTATION_SET_TRADING_ACCOUNTS_LOADING';
export const MUTATION_SET_TRADING_ACCOUNTS = 'MUTATION_SET_TRADING_ACCOUNTS';
export const MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS_LOADING = 'MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS_LOADING';
export const MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS = 'MUTATION_SET_ARCHIVED_TRADING_ACCOUNTS';
export const MUTATION_SET_ACCOUNT_PROGRESS = 'MUTATION_SET_ACCOUNT_PROGRESS';
export const MUTATION_SET_IS_ACCOUNT_PROGRESS_LOADING = 'MUTATION_SET_IS_ACCOUNT_PROGRESS_LOADING';
export const MUTATION_SET_ACTIVE_ACCOUNT = 'MUTATION_SET_ACTIVE_ACCOUNT';
export const MUTATION_SET_ECN_PROMO = 'MUTATION_SET_ECN_PROMO';
export const MUTATION_SET_IS_ECN_PROMO_LOADING = 'MUTATION_SET_IS_ECN_PROMO_LOADING';
export const MUTATION_SET_IS_ENABLE_ECN_PROMO_LOADING = 'MUTATION_SET_IS_ENABLE_ECN_PROMO_LOADING';

export const MUTATION_SET_IS_TRADES_LOADING = 'MUTATION_SET_IS_TRADES_LOADING';
export const MUTATION_SET_TRADES = 'MUTATION_SET_TRADES';
export const MUTATION_SET_TRADES_TOTAL = 'MUTATION_SET_TRADES_TOTAL';

export const MUTATION_ADD_TRADES = 'MUTATION_ADD_TRADES';

export const MUTATION_SET_TRADES_PAGES_COUNT = 'MUTATION_SET_TRADES_PAGES_COUNT';

export const MUTATION_SET_APPLICATION_TITLE = 'MUTATION_SET_APPLICATION_TITLE';
export const MUTATION_SET_IS_OPEN_SUCCESS_VERIFICATION_MODAL = 'MUTATION_SET_IS_OPEN_SUCCESS_VERIFICATION_MODAL';

export const MUTATION_SET_WALLET_ID = 'MUTATION_SET_WALLET_ID';

export const MUTATION_SET_USER_COUNTRY = 'MUTATION_SET_USER_COUNTRY';

export const MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES = 'MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES';
export const MUTATION_SET_WITHDRAWAl_PROCESSING_DATA = 'MUTATION_SET_WITHDRAWAl_PROCESSING_DATA';

export const MUTATION_SET_USER_IP = 'MUTATION_SET_USER_IP';

// getters
export const GETTER_USER_WALLET_ID = 'GETTER_USER_WALLET_ID';
export const GETTER_USER_COUNTRY = 'GETTER_USER_COUNTRY';

export const GETTER_USER_UID = 'GETTER_USER_UID';
export const GETTER_USER_PHONE = 'GETTER_USER_PHONE';
export const GETTER_USER_DATA = 'GETTER_USER_DATA';
export const GETTER_IS_USER_DATA_LOADING = 'GETTER_IS_USER_DATA_LOADING';
export const GETTER_USER_EMAIL = 'GETTER_USER_EMAIL';
export const GETTER_ACCOUNTS_DATA = 'GETTER_ACCOUNTS_DATA';
export const GETTER_ACCOUNTS_DATA_LOADING = 'GETTER_ACCOUNTS_DATA_LOADING';
export const GETTER_CURRENT_USER_ROLE = 'GETTER_CURRENT_USER_ROLE';
export const GETTER_HAS_NOT_USER_PARTNER_ROLE = 'GETTER_HAS_NOT_USER_PARTNER_ROLE';
export const GETTER_USER_PROFILE = 'GETTER_USER_PROFILE';
export const GETTER_IS_USER_PROFILE_LOADING = 'GETTER_IS_USER_PROFILE_LOADING';

export const GETTER_PARTNER_DATA = 'GETTER_PARTNER_DATA';
export const GETTER_IS_PARTNER_DATA_LOADING = 'GETTER_IS_PARTNER_DATA_LOADING';

export const GETTER_TRADING_ACCOUNTS = 'GETTER_TRADING_ACCOUNTS';
export const GETTER_ALL_ACCOUNTS = 'GETTER_ALL_ACCOUNTS';
export const GETTER_IS_ALL_ACCOUNTS_LOADING = 'GETTER_IS_ALL_ACCOUNTS_LOADING';
export const GETTER_TRADING_ACCOUNTS_LOADING = 'GETTER_TRADING_ACCOUNTS_LOADING';
export const GETTER_ACCOUNT_PROGRESS = 'GETTER_ACCOUNT_PROGRESS';
export const GETTER_IS_ACCOUNT_PROGRESS_LOADING = 'GETTER_IS_ACCOUNT_PROGRESS_LOADING';
export const GETTER_ACTIVE_ACCOUNT = 'GETTER_ACTIVE_ACCOUNT';
export const GETTER_ECN_PROMO = 'GETTER_ECN_PROMO';
export const GETTER_IS_ECN_PROMO_LOADING = 'GETTER_IS_ECN_PROMO_LOADING';
export const GETTER_IS_ENABLE_ECN_PROMO_LOADING = 'GETTER_IS_ENABLE_ECN_PROMO_LOADING';

export const GETTER_IS_TRADES_LOADING = 'GETTER_IS_TRADES_LOADING';
export const GETTER_TRADES = 'GETTER_TRADES';
export const GETTER_TRADES_TOTAL = 'GETTER_TRADES_TOTAL';
export const GETTER_TRADES_PAGES_COUNT = 'GETTERS_TRADES_PAGES_COUNT';
export const GETTER_ARCHIVED_TRADING_ACCOUNTS_LOADING = 'GETTER_ARCHIVED_TRADING_ACCOUNTS_LOADING';
export const GETTER_ARCHIVED_TRADING_ACCOUNTS = 'GETTER_ARCHIVED_TRADING_ACCOUNTS';

export const GETTER_IS_NEW_USER_PROFILE = 'GETTER_IS_NEW_USER_PROFILE';

export const GETTER_APPLICATION_TITLE = 'GETTER_APPLICATION_TITLE';
export const GETTER_USER_FROM_COUNTRY_WITHOUT_REQUIRED_VERIFICATION = 'GETTER_USER_FROM_COUNTRY_WITHOUT_REQUIRED_VERIFICATION';
export const GETTER_IS_SHOW_SUCCESS_VERIFICATION_MODAL = 'GETTER_IS_SHOW_SUCCESS_VERIFICATION_MODAL';

export const GETTER_MAIN_CHANNEL_SOCKET_MESSAGES = 'GETTER_MAIN_CHANNEL_SOCKET_MESSAGES';
export const GETTER_WITHDRAWAL_PROCESSING_DATA = 'GETTER_WITHDRAWAL_PROCESSING_DATA';

export const GETTER_USER_IP = 'GETTER_USER_IP';
