export default {
  ROOT: {
    PATH: '/',
    NAME: 'home',
  },
  MAIN: {
    PATH: '/main',
    NAME: 'main',
  },
  USER_BLOCKED: {
    PATH: '/blocked',
    NAME: 'user-blocked',
  },
  SECURITY: {
    PATH: '/security',
    NAME: 'security',
  },
  TWO_FACTOR_ENABLE: {
    PATH: '/two-factor-enable',
    NAME: 'two-factor-enable',
  },
  VERIFICATION: {
    PATH: '/verification',
    NAME: 'verification',
  },
  PROFILE: {
    PATH: '/profile',
    NAME: 'profile',
  },
  FINANCE: {
    PATH: '/finance',
    NAME: 'finance',
  },
  DEBUG: {
    PATH: '/webview-debug',
    NAME: 'debug',
  },
  MT_WEB: {
    PATH: '/mt-web/:type',
    NAME: 'mt-web',
  },
  INVITE_FRIEND: {
    PATH: '/invite-a-friend',
    NAME: 'invite-a-friend',
  },
  INVITE_FRIEND_REFERRER: {
    PATH: '/invite-a-friend/referrer',
    NAME: 'referrer',
  },
  INVITE_FRIEND_REFERRAL: {
    PATH: '/invite-a-friend/referral',
    NAME: 'referral',
  },
  DOC_VERIFIER: {
    PATH: '/doc-verifier',
    NAME: 'doc-verifier',
  },
  FCOUPON: {
    PATH: '/fcoupons',
    NAME: 'fcoupons',
  },
  CASHBACK: {
    PATH: '/cashback',
    NAME: 'cashback',
  },
};
