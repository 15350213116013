import { Action } from '@amarkets/atlas/esm/lib/action';
import { AccountProgress } from '../entities/account-progress';
import { AccountProgressMapper } from '../mappers/account-progress-mapper';
import {
  MUTATION_SET_IS_ACCOUNT_PROGRESS_LOADING,
  MUTATION_SET_ACCOUNT_PROGRESS,
} from '../types';

export class FetchAccountProgress extends Action {
  _api;
  _errorHandler;

  constructor({ agoraApi, errorHandler }) {
    super();

    this._api = agoraApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, id) {
    if (!id) return;

    await FetchAccountProgress._withLoading(async () => {
      try {
        const accountProgressMapper = new AccountProgressMapper((opts) => new AccountProgress(opts));
        const accountProgress = await this._api.getAccountProgress(
          id, (data) => accountProgressMapper.mapAccountProgress(data),
        );
        commit(MUTATION_SET_ACCOUNT_PROGRESS, accountProgress);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchAccountProgress');
      }
    }, commit, MUTATION_SET_IS_ACCOUNT_PROGRESS_LOADING);
  }
}
