import { USER_ROLES } from '@/constants';

export default {
  ip: '',
  user: {
    currentUserRole: USER_ROLES.TRADER,
  },
  isUserDataLoading: false,
  partner: {},
  isPartnerDataLoading: false,
  accounts: {},
  isAccountsDataLoading: false,
  userProfile: {},
  isUserProfileLoading: false,
  isTradingAccountsLoading: true,
  tradingAccounts: [],
  isArchivedTradingAccountsLoading: false,
  archivedTradingAccounts: [],
  isTradesLoading: true,
  trades: [],
  tradesTotal: 0,
  tradesPagesCount: 1,
  homePage: {
    accountProgress: {},
    isAccountProgressLoading: null,
    allAccounts: {},
    isAllAccountsLoading: true,
  },
  ecnPromo: {},
  isEcnPromoLoading: false,
  isEnableEcnPromoLoading: false,
  applicationTitle: 'common.document.title',
  activeAccount: null,
  isOpenSuccessVerificationModal: false,
  walletId: 0,
  userCountry: '',
  mainChannelSocketMessages: [],
  withdrawalProcessingData: null,
};
